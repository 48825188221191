( () => {
	window.StretchMediaBG = function ( container, media ) {
		if ( !container || !media ) {
			return;
		}
		function update() {
			var media_aspect_ratio = parseFloat( media.getAttribute( 'data-stretchmedia-aspect-ratio' ) );
			var window_aspect_ratio = container.offsetHeight / container.offsetWidth;
			if ( media_aspect_ratio <= window_aspect_ratio ) {
				media.style.height = container.offsetHeight + 'px';
				media.style.width = container.offsetHeight * ( 1 / media_aspect_ratio ) + 'px';
				media.style.top = 0;
				media.style.left = ( media.offsetWidth - container.offsetWidth ) / -2 + 'px';
			} else {
				media.style.width = container.offsetWidth + 'px';
				media.style.height = container.offsetWidth * media_aspect_ratio + 'px';
				media.style.top = ( media.offsetHeight - container.offsetHeight ) / -2 + 'px';
				media.style.left = 0;
			}
		}
		window.addEventListener( 'resize', () => {
			update();
		} );
		update();
	};
} )();