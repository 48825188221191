/* globals StretchMediaBG */
// import { breakpoints } from '../variables.yml';

import { breakpoints } from '../variables.yml';
import 'focus-visible';
import './vendor/stretchmedia.js';
// import 'objectFitPolyfill';
import './vendor/ajax';
import './vendor/swipe';
import 'intersection-observer';

// modules
import { getWindowWidth } from './helpers.js';
import { LightBox } from './vendor/LightBox.js';
import Player from '@vimeo/player';
import EmblaCarousel from 'embla-carousel';
// import Autoplay from 'embla-carousel-autoplay';
// import ClassNames from 'embla-carousel-class-names';
// import { add_pips, add_next_prev_clicks, handle_video_slide } from './vendor/embla-helpers';
import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';

export * from './CookieConsentManager.js';

( () => {
	// Lazy loading
	const lazy_items = document.querySelectorAll( '[data-lazy-iframe], [data-lazy-src]' );
	if ( !lazy_items ) {
		return;
	}

	function restoreItem( element ) {
		if ( element.hasAttribute( 'data-lazy-iframe' ) ) {
			const src = element.getAttribute( 'data-lazy-iframe' );
			const iframe = document.createElement( 'iframe' );
			iframe.src = src;
			element.classList.remove( 'lazy--unloaded' );
			element.appendChild( iframe );
		} else {
			const src = element.getAttribute( 'data-lazy-src' );
			const image = document.createElement( 'img' );
			image.addEventListener( 'load', () => {
				element.classList.remove( 'lazy--unloaded' );
			} );
			image.src = src;
			element.appendChild( image );
		}
	}

	if ( typeof window.IntersectionObserver === 'undefined' ) {
		for ( let i = 0; i < lazy_items.length; i++ ) {
			restoreItem( lazy_items[i] );
		}
	}

	const observer = new IntersectionObserver( ( entries ) => {
		for ( let i = 0; i < entries.length; i++ ) {
			if ( !entries[i].isIntersecting ) {
				continue;
			}
			restoreItem( entries[i].target );
			observer.unobserve( entries[i].target );
		}
	}, {
		// Adjust the point in relation to the viewport at which the item is loaded.
		rootMargin: '-50px 0px -50px 0px',
	} );

	for ( let i = 0; i < lazy_items.length; i++ ) {
		observer.observe( lazy_items[i] );
	}
} )();

( () => {
	// Smooth scrolling
	const smooth_scroll = e => {
		e.preventDefault();
		window.scroll( {
			top: 0,
			behavior: 'smooth'
		} );
	};

	const scroll_triggers = document.querySelectorAll( '.to-top-link' );
	for ( let i = 0; i < scroll_triggers.length; i++ ) {
		scroll_triggers[i].addEventListener( 'click', smooth_scroll );
	}
} )();

( () => {
	window.addEventListener( 'click', ( e ) => {
		if ( !e.target.classList.contains( 'video--playable' ) ) {
			return;
		}
		e.target.classList.remove( 'video--playable' );
		const video = e.target.querySelector( 'iframe' );
		if ( video ) {
			const player = new Player( video );
			player.play();
		}
	} );
} )();

( () => {
	// Dropdown
	const dropdown = document.querySelector( '.dropdown' );
	if ( dropdown ) {
		const dropdown_trigger = document.querySelector( '.dropdown__trigger' );
		dropdown_trigger.addEventListener( 'click', () => {
			dropdown.classList.toggle( 'dropdown--open' );
		} );
	}
} )();
( () => {
	// Navigation Toggle
	const nav_dropdowns = document.querySelectorAll( '.has-submenu' );

	for ( let i = 0; i < nav_dropdowns.length; i++ ) {
		const link = nav_dropdowns[i];
		link.addEventListener( 'click', function() {
			// e.preventDefault();
			if ( this.classList.contains( 'nav-open' ) ) {
				closeAllNavs();
				return;
			}
			closeAllNavs();
			this.classList.add( 'nav-open' );
			this.setAttribute( 'aria-expanded', true );
			this.lastElementChild.classList.add( 'open' );
		} );
	}

	function closeAllNavs() {
		for ( let i = 0; i < nav_dropdowns.length; i++ ) {
			const item = nav_dropdowns[i];
			item.lastElementChild.classList.remove( 'open' );
			item.classList.remove( 'nav-open' );
			item.setAttribute( 'aria-expanded', false );
		}
	}
} )();

( () => {
	// Overlay
	const overlay_triggers = document.querySelectorAll( '[data-overlay-trigger="true"]' );
	if ( !overlay_triggers ) {
		return;
	}
	const overlay_close = document.querySelector( '.overlay__close' );
	const overlay = document.querySelector( '.overlay' );

	window.addEventListener( 'popstate', ( event ) => {
		if ( event.state === null && !document.body.classList.contains( 'overlay-open' ) ) {
			location.reload();
		}
		if ( event.state && Object.prototype.hasOwnProperty.call( event.state, 'overlay_open' ) && event.state.overlay_open === true ) {
		// if ( event.state && event.state.hasOwnProperty( 'overlay_open' ) && event.state.overlay_open === true ) {
			get_entry( window.location, append_content );
		} else {
			if ( document.body.classList.contains( 'overlay-loading' ) ) {
				document.body.classList.remove( 'overlay-loading' );
			} else if ( document.body.classList.contains( 'overlay-open' ) ) {
				const content = document.querySelector( '.overlay__content' );
				overlay.removeChild( content );
				document.body.classList.remove( 'overlay-open' );
			}
		}
	} );

	const append_content = ( content ) => {
		const content_wrapper = document.createElement( 'div' );
		content_wrapper.classList.add( 'overlay__content' );
		overlay.appendChild( content_wrapper );
		if ( history.state && history.state.overlay_alt_layout ) {
			content_wrapper.classList.add( 'overlay__content--alt' );
		}
		content_wrapper.appendChild( content );
		document.body.classList.remove( 'overlay-loading' );
		content_wrapper.style.opacity = 1;
		document.body.classList.add( 'overlay-open' );
	};

	const get_entry = ( url, on_success ) => {
		document.body.classList.add( 'overlay-loading' );
		const xmlhr = new XMLHttpRequest();
		xmlhr.addEventListener( 'readystatechange', () => {
			if ( xmlhr.readyState === 4 && xmlhr.status === 200 ) {
				const entry_page = ( new DOMParser() ).parseFromString( xmlhr.response, 'text/html' );
				const entry_content = entry_page.querySelector( '#overlay-content' );
				on_success( entry_content );
			}
		} );
		xmlhr.open( 'GET', url, true );
		xmlhr.send();
	};
	const handle_overlay_trigger_click = ( e ) => {
		e.preventDefault();
		const url = e.currentTarget.href;
		const state = {
			overlay_open: true,
			overlay_alt_layout: e.currentTarget.getAttribute( 'data-overlay-alt-layout' ) === 'true'
		};
		history.pushState( state, '', url );
		get_entry( url, append_content );
	};
	for ( let i = 0; i < overlay_triggers.length; i++ ) {
		overlay_triggers[i].addEventListener( 'click', handle_overlay_trigger_click );
	}
	if ( overlay_close ) {
		overlay_close.addEventListener( 'click', () => {
			history.go( -1 );
		} );
	}
} )();

( () => {
	// const predictive_search_container = document.querySelector( '.predictive-search' );
	// const recommended_searches = predictive_search_container.querySelectorAll( '.recommended-search__item' );
	// const search_trigger = document.querySelector( '#search-trigger' );
	// const search_close = document.querySelector( '#close-search' );
	// const search_result_containers = document.querySelectorAll( '.search-results' );
	// const search_inputs = document.querySelectorAll( '.search-form__input' );
	// if ( !search_trigger ) {
	// 	return;
	// }
	// search_trigger.addEventListener( 'click', e => {
	// 	e.preventDefault();
	// 	setTimeout( () => {
	// 		search_inputs[0].focus();
	// 	}, 100 );
	// 	document.body.classList.add( 'search-open' );
	// } );
	// search_close.addEventListener( 'click', () => {
	// 	document.body.classList.remove( 'search-open' );
	// 	search_result_containers[0].innerHTML = '';
	// 	search_inputs[0].value = '';
	// 	search_inputs[0].blur();
	// } );
	// const searchRecommended = e => {
	// 	e.preventDefault();
	// 	makeRequest( e.target.innerHTML, search_result_containers );
	// 	search_inputs[0].value = e.target.innerHTML;
	// };
	// for ( let i = 0; i < recommended_searches.length; i++ ) {
	// 	recommended_searches[i].addEventListener( 'click', searchRecommended );
	// }

	// const delay = ( () => {
	// 	let timer = 0;
	// 	return ( callback, ms ) => {
	// 		clearTimeout( timer );
	// 		timer = setTimeout( callback, ms );
	// 	};
	// } )();

	// window.addEventListener( 'keyup', e => {
	// 	if ( !e.target.classList.contains( 'search-form__input' ) ) {
	// 		return;
	// 	}
	// 	delay( () => {
	// 		if ( e.target.value.length > 0 ) {
	// 			makeRequest( e.target.value, search_result_containers );
	// 		} else {
	// 			for ( let i = 0; i < search_result_containers.length; i++ ) {
	// 				search_result_containers[i].innerHTML = '';
	// 			}
	// 		}
	// 	}, 500 );
	// } );

	// const makeRequest = ( value, search_result_containers ) => {
	// 	document.body.classList.add( 'search-loading' );
	// 	const base_url = document.getElementById( 'base-url' ).getAttribute( 'data-url' );
	// 	const url = base_url + 'api/' + value.replace( / /g, '_' ) + '.json';
	// 	for ( let i = 0; i < search_result_containers.length; i++ ) {
	// 		search_result_containers[i].innerHTML = '';
	// 	}
	// 	ajax( {
	// 		type: 'get',
	// 		url: url,
	// 		timeout: 10000,
	// 		onSuccess: ( data ) => {
	// 			data = JSON.parse( data ).data;
	// 			let html = '';
	// 			if ( data.length === 0 ) {
	// 				html = '<p>No results found, try specifying your search</p>';
	// 			} else {
	// 				for ( let i = 0; i < data.length; i++ ) {
	// 					html += `
	// 					<div class="grid__item a4-20 d5-20 f3-6 g6-6 h6-6">
	// 						<a href="${data[i].url}" class="listing-item listing-item--new">
	// 							<img src="${data[i].image}" class="listing-item__image">
	// 								<p class="listing-item__title">${data[i].title}</p>
	// 							</a>
	// 						</div>`;

	// 				}
	// 			}
	// 			for ( let i = 0; i < search_result_containers.length; i++ ) {
	// 				search_result_containers[i].innerHTML = `
	// 				<div class="flex flex--wrap flex--justify-center grid">
	// 					${html}
	// 				</div>
	// 				`;
	// 				const new_items = search_result_containers[i].querySelectorAll( '.listing-item--new' );
	// 				document.body.classList.remove( 'search-loading' );
	// 				if ( new_items.length > 0 ) {
	// 					( function revealSearchResult( i = 0 ) {
	// 						setTimeout( () => {
	// 							new_items[i].classList.remove( 'listing-item--new' );
	// 							i++;
	// 							if ( i < new_items.length ) {
	// 								revealSearchResult( i );
	// 							}
	// 						}, 100 );
	// 					} )( 0 );
	// 				}
	// 			}
	// 		},
	// 		onError: () => {
	// 			document.body.classList.remove( 'search-loading' );
	// 			for ( let i = 0; i < search_result_containers.length; i++ ) {
	// 				search_result_containers[i].innerHTML = `
	// 				<div class="flex flex--wrap flex--justify-center grid">
	// 					<p>No results found, try specifying your search</p>
	// 				</div>`;
	// 			}
	// 		}
	// 	} );
	// };

} )();

( () => {
	// Preload video embeds
	const video_embeds = document.querySelectorAll( '.video' );
	if ( !video_embeds ) {
		return;
	}

	const options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.25
	};

	const io = new IntersectionObserver( ( entries ) => {
		entries.forEach( entry => {
			const poster = entry.target.querySelector( '.video__poster' );
			const iframe = entry.target.querySelector( 'iframe' );
			if ( !iframe ) {
				return;
			}
			const player = new Player( iframe );
			if ( entry.intersectionRatio < 0.25 ) {
				player.pause();
			} else {
				player.play();
				player.addCuePoint( 0 );
				player.on( 'cuepoint', () => {
					poster.classList.add( 'video__poster--faded' );
				} );
			}
		} );
	}, options );

	for ( let i = 0; i < video_embeds.length; i++ ) {
		const target = video_embeds[i];
		io.observe( target );
	}
} )();

( () => {
	// Stretchy video
	const videos = document.querySelectorAll( '.video--hero, .video--listing' );
	if ( !videos ) {
		return;
	}

	for ( let i = 0; i < videos.length; i++ ) {
		const vid = videos[i].querySelector( 'iframe' );
		new StretchMediaBG( videos[i], vid );
	}
} )();

( () => {
	new LightBox();
} )();

// ( () => {
// 	window.objectFitPolyfill();
// } )();

( () => {
	// Fixed nav
	// const nav = document.querySelector( '.nav' );
	// let prev_scroll_pos = 0;
	// window.addEventListener( 'scroll', () => {
	// 	const current_scroll_pos = document.documentElement.scrollTop;
	// 	if ( current_scroll_pos > 800 ) {
	// 		// If user is scrolling up
	// 		if ( current_scroll_pos < prev_scroll_pos ) {
	// 			nav.classList.add( 'nav--fixed' );
	// 			nav.style.opacity = 1;
	// 		} else {
	// 			nav.style.opacity = 0;
	// 		}
	// 	} else {
	// 		nav.style.opacity = 1;
	// 		nav.classList.remove( 'nav--fixed' );
	// 	}
	// 	prev_scroll_pos = current_scroll_pos;
	// } );
} )();

// ( () => {
// 	// Slideshows
// 	const embla_nodes = document.querySelectorAll( '.embla' );
// 	if ( !embla_nodes ) {
// 		return;
// 	}
// 	const options = {
// 		loop: true,
// 		align: 'start',
// 	};

// 	embla_nodes.forEach( embla_node => {
// 		const viewport_node = embla_node.querySelector( '.embla__viewport' );
// 		const has_fade = embla_node.classList.contains( 'embla--fade' );
// 		const pips = document.querySelector( '.embla__pips' );
// 		const prev = embla_node.querySelector( '.embla__button--prev' );
// 		const next = embla_node.querySelector( '.embla__button--next' );
// 		const data_points = [...embla_node.querySelectorAll( '.embla__data-point' )];

// 		const embla = EmblaCarousel( viewport_node, options, [ClassNames(), Autoplay( {delay: 5000} )] );

// 		const remove_pip_handlers = add_pips(
// 			embla,
// 			pips,
// 			data_points
// 		);
// 		const remove_prev_next_handlers = add_next_prev_clicks(
// 			embla,
// 			prev,
// 			next
// 		);

// 		const remove_handle_video = handle_video_slide(
// 			embla,
// 			embla_node
// 		);

// 		const toggle_embla_ready = ( embla, event ) => {
// 			const is_resize_event = event === 'resize';
// 			const toggle_class = is_resize_event ? 'remove' : 'add';
// 			viewport_node.classList[toggle_class]( 'embla--is-ready' );
// 			if ( is_resize_event ) {
// 				embla.reInit();
// 			}
// 		};

// 		embla
// 			.on( 'destroy', remove_pip_handlers )
// 			.on( 'destroy', remove_prev_next_handlers )
// 			.on( 'destroy', remove_handle_video );

// 		if ( has_fade ) {
// 			embla
// 				.on( 'init', toggle_embla_ready )
// 				.on( 'resize', toggle_embla_ready )
// 				.on( 'reInit', toggle_embla_ready );
// 		}

// 		embla.internalEngine().translate.toggleActive( false );
// 		embla.internalEngine().translate.clear();

// 	} );

// } )();

( () => {
	// Detail slideshow mobile
	const embla_nodes = document.querySelectorAll( '.embla-mobile' );
	if ( !embla_nodes ) {
		return;
	}

	const options = {
		loop: false,
		align: 'start',
		containScroll: 'trimSnaps'
	};

	function showOrHideControls( slider, link_prev, link_next ) {
		return () => {
			const slides_not_in_view = slider.slidesNotInView();
			if ( slides_not_in_view.length > 0 ) {
				link_prev.classList.add( 'embla-mobile__link--active' );
				link_next.classList.add( 'embla-mobile__link--active' );
				return;
			}
			link_prev.classList.remove( 'embla-mobile__link--active' );
			link_next.classList.remove( 'embla-mobile__link--active' );
		};
	}

	initSlideshows();
	window.addEventListener( 'resize', () => {
		initSlideshows();
	} );

	function initSlideshows() {
		for ( let i = 0; i < embla_nodes.length; i++ ) {
			if ( getWindowWidth() > breakpoints.e.width ) {
				continue;
			}

			const slider = EmblaCarousel( embla_nodes[i], options );

			if (
				typeof embla_nodes[i].dataset.emblaPrev == 'undefined'
				|| typeof embla_nodes[i].dataset.emblaNext == 'undefined'
			) {
				continue;
			}

			const link_prev = document.querySelector( embla_nodes[i].dataset.emblaPrev );
			const link_next = document.querySelector( embla_nodes[i].dataset.emblaNext );

			link_prev.addEventListener( 'click', () => {
				slider.scrollPrev();
			}, false );

			link_next.addEventListener( 'click', () => {
				slider.scrollNext();
			}, false );

			const handle_show_or_hide_controls = showOrHideControls( slider, link_prev, link_next );
			window.addEventListener(
				'resize',
				handle_show_or_hide_controls
			);
			handle_show_or_hide_controls( null );
		}
	}

} )();

( () => {
	// Swiper
	const swiper_node = document.querySelector( '.swiper' );
	if ( !swiper_node ) {
		return;
	}
	const timer = swiper_node.querySelector( '.swiper__timer' );
	const timer_inner = swiper_node.querySelector( '.swiper__timer-inner' );
	const data_points = [...swiper_node.querySelectorAll( '.swiper__data-point' )];
	const videos = swiper_node.querySelectorAll( 'video' );

	const swiper = new Swiper( swiper_node, {
		modules: [Pagination, EffectFade, Autoplay],
		loop: true,
		speed: 1000,
		effect: 'fade',
		allowTouchMove: false,
		fadeEffect: {
			crossFade: true,
		},
		pagination: {
			el: '.swiper__pips',
		},
		autoplay: {
			delay: 4000,
			disableOnInteraction: false
		},
		on: {
			init: ( swiper ) => {
				return handleCurrent( swiper );
			},
			realIndexChange: ( swiper ) => {
				timer.classList.remove( 'swiper__timer--animate' );
				return handleCurrent( swiper );
			},
		}
	} );

	function handleCurrent( swiper ) {
		const current_slide = swiper.slides[swiper.activeIndex];
		const default_duration = 4000;
		let duration = 0;

		for ( let i = 0; i < data_points.length; i++ ) {
			const point = data_points[i];
			point.classList.remove( 'swiper__data-point--selected' );
		}
		data_points[swiper.realIndex].classList.add( 'swiper__data-point--selected' );

		if ( current_slide.dataset.slideType === 'videoSlide' ) {
			const video = videos[swiper.realIndex];
			swiper.autoplay.stop();
			if ( !isNaN( video.duration ) ) {
				video.play();
				duration = current_slide.getAttribute( 'data-swiper-autoplay' );
				timer_inner.style.animationDuration = ( duration ) + 'ms';
				setTimeout( () => {
					timer.classList.add( 'swiper__timer--animate' );
				}, 10 );
				swiper.autoplay.start();
			} else {
				video.addEventListener( 'loadedmetadata', () => {
					video.play();
					duration = current_slide.getAttribute( 'data-swiper-autoplay' );
					timer_inner.style.animationDuration = ( duration ) + 'ms';
					setTimeout( () => {
						timer.classList.add( 'swiper__timer--animate' );
					}, 10 );
					swiper.autoplay.start();
				} );
			}

		} else {
			duration = default_duration;
			timer_inner.style.animationDuration = duration + 'ms';
			setTimeout( () => {
				timer.classList.add( 'swiper__timer--animate' );
			}, 10 );
			swiper.autoplay.start();
		}
	}
} )();

( () => {
	// Play video on intersect
	const vids = document.querySelectorAll( '.video__autoplay' );
	if ( !vids ) {
		return;
	}

	const options = {
		root: null,
		rootMargin: '0px',
		threshold: 1.0,
	};

	const io = new IntersectionObserver( ( entries ) => {
		entries.forEach( entry => {
			const video = entry.target;
			if ( entry.isIntersecting ) {
				video.previousElementSibling.style.opacity = 0;
				video.play();
			} else {
				video.previousElementSibling.style.opacity = 1;
				video.pause();
			}
		} );
	}, options );

	for ( let i = 0; i < vids.length; i++ ) {
		const target = vids[i];
		io.observe( target );
	}
} )();